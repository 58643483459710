import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { SongClueAnswerType, SongClueStatus } from '@api/models/enums';
import { SongClue } from '@api/models/song-clue.entity';
import { SongRound } from '@api/models/song-round.entity';
import { Song } from '@api/models/song.entity';
import { DeviceFacade } from '@bussiness/store/features/device/device.facade';
import { SongPhaseClueUpdatedEvent } from '@core/models/games/events/song-phase.events';
import { SongPhaseSockets } from '@core/services/sockets/song-phase.sockets';
import { concatLatestFrom } from '@ngrx/effects';
import { Store } from '@ngrx/store';

import { SongRoundFacade } from '../song-round/song-round.facade';
import { SongClueActions } from './song-clue.actions';
import { IndexedSongClue, SongClueSelectors } from './song-clue.selectors';

@Injectable()
export class SongClueFacade {
	public current$ = (songRoundId: string): Observable<IndexedSongClue> =>
		this.store.select(SongClueSelectors.current(songRoundId));

	public currentFromPlayer$: Observable<SongClue> =
		this.songRoundFacade.current$.pipe(
			filter((round: SongRound) => !!round),
			map(({ clues }: SongRound) =>
				clues.find(
					({ status }: SongClue) =>
						status !== SongClueStatus.Answered,
				),
			),
		);

	public allCluesAreAnswered$ = (songRoundId: string): Observable<boolean> =>
		this.store.select(SongClueSelectors.allCluesAreAnswered(songRoundId));

	public lastClueAnswered$ = (
		songRoundId: string,
	): Observable<IndexedSongClue> =>
		this.store.select(SongClueSelectors.lastClueAnswered(songRoundId));

	private _answerAvailableTimestamp: number;

	constructor(
		private store: Store,
		private songRoundFacade: SongRoundFacade,
		private songPhaseSockets: SongPhaseSockets,
		private deviceFacade: DeviceFacade,
	) {
		this.songPhaseSockets.clueUpdated$
			.pipe(
				concatLatestFrom(({ songRoundId }: SongPhaseClueUpdatedEvent) =>
					this.songRoundFacade.song$(songRoundId),
				),
			)
			.subscribe(([event, song]: [SongPhaseClueUpdatedEvent, Song]) => {
				const {
					songRoundId,
					songClue: { _id: songClueId, status, turnOwner, from, to },
				} = event;

				if (status === SongClueStatus.ReadyForAnswer)
					this._answerAvailableTimestamp = Date.now();
				else this._answerAvailableTimestamp = null;

				if (status === SongClueStatus.Playing) {
					const options =
						from !== null && to !== null ? { from, to } : null;
					this.deviceFacade.playAudio(song._id, options);
				}

				this.store.dispatch(
					SongClueActions.updateSuccess({
						songRoundId,
						clue: { _id: songClueId, turnOwner, status },
					}),
				);
			});
	}

	public updateStatus(
		songRoundId: string,
		status: SongClueStatus.Playing | SongClueStatus.ReadyForAnswer,
	): void {
		this.store.dispatch(
			SongClueActions.updateStatus({ songRoundId, status }),
		);
	}

	public tryToAnswer(timestamp: number): void {
		this.store.dispatch(
			SongClueActions.tryToAnswer({
				reactionMiliseconds: timestamp - this._answerAvailableTimestamp,
			}),
		);
	}

	public validateAnswer(
		songRoundId: string,
		answerType: SongClueAnswerType,
	): void {
		this.store.dispatch(
			SongClueActions.validateAnswer({ songRoundId, answerType }),
		);
	}
}
