import { Observable } from 'rxjs';
import { map, withLatestFrom } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { SongClueStatus, Team } from '@api/models/enums';
import { Player } from '@api/models/player.entity';
import { SongClue } from '@api/models/song-clue.entity';
import { SongRound } from '@api/models/song-round.entity';
import { User } from '@api/models/user.entity';
import { PlayersFacade } from '@bussiness/store/features/games/players/players.facade';
import { PlcToggleButtonThreeOption } from '@components/modules/common/toggle-button-three/toggle-button-three.component';
import { SongPhaseSockets } from '@core/services/sockets/song-phase.sockets';
import { Store } from '@ngrx/store';

import { SongPhaseActions } from './song-phase.actions';
import { SongPhaseSelectors } from './song-phase.selectors';
import { SongRoundSelectors } from './song-round/song-round.selectors';

@Injectable()
export class SongPhaseFacade {
	public playersByRound$: Observable<PlcToggleButtonThreeOption[]> =
		this.store.select(SongRoundSelectors.all).pipe(
			withLatestFrom(this.playersFacade.all$),
			map(([rounds, players]: [SongRound[], Player[]]) =>
				rounds.map(({ _id, players: [p1, p2] }) => ({
					key: _id,
					items: [
						{
							value: players
								.filter(({ _id }: Player) => _id === p1)
								.map(
									({ user }) =>
										(user as Partial<User>).avatarUrl,
								)
								.pop(),
							type: 'img',
						},
						{
							value: players
								.filter(({ _id }: Player) => _id === p2)
								.map(
									({ user }) =>
										(user as Partial<User>).avatarUrl,
								)
								.pop(),
							type: 'img',
						},
					],
				})),
			),
		);

	public over$ = this.store.select(SongPhaseSelectors.over);

	public totalScore$ = (team: Team): Observable<number> =>
		this.store.select(SongRoundSelectors.all).pipe(
			withLatestFrom(this.playersFacade.all$),
			map(([rounds, players]: [SongRound[], Player[]]) => {
				const teamPlayerIds = players
					.filter(
						({ team: playerTeam }: Player) => playerTeam === team,
					)
					.map(({ _id }: Player) => _id);

				const roundsHittedByTeam = rounds.filter(
					({ hittedBy }: SongRound) =>
						teamPlayerIds.includes(hittedBy as string),
				);

				return roundsHittedByTeam
					.map(({ clues }: SongRound) =>
						clues
							.filter(
								({ status }: SongClue) =>
									status === SongClueStatus.Answered,
							)
							.map(({ score }: SongClue) => score)
							.pop(),
					)
					.reduce((acc, curr) => acc + curr, 0);
			}),
		);

	constructor(
		private store: Store,
		private playersFacade: PlayersFacade,
		private songPhaseSockets: SongPhaseSockets,
	) {
		this.songPhaseSockets.finished$.subscribe(() =>
			this.store.dispatch(SongPhaseActions.finish()),
		);
	}

	public preloadSongs(): void {
		this.store.dispatch(SongPhaseActions.preloadSongs());
	}
}
