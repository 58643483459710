import { Observable } from 'rxjs';
import { filter, map, switchMap, withLatestFrom } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { Team } from '@api/models/enums';
import { Player } from '@api/models/player.entity';
import { SongRound } from '@api/models/song-round.entity';
import { Song } from '@api/models/song.entity';
import { User } from '@api/models/user.entity';
import { DeviceFacade } from '@bussiness/store/features/device/device.facade';
import { SongPhaseRoundUpdatedEvent } from '@core/models/games/events/song-phase.events';
import { SongPhaseSockets } from '@core/services/sockets/song-phase.sockets';
import { concatLatestFrom } from '@ngrx/effects';
import { Store } from '@ngrx/store';

import { PlayersFacade } from '../../players/players.facade';
import { SongRoundActions } from './song-round.actions';
import { SongRoundSelectors } from './song-round.selectors';

export class SongRoundPlayerView {
	public playerId: string;
	public avatar: string;
	public team: Team;
	public isCurrentUser?: boolean;
}

const BREAKLINE = '<br>';
@Injectable()
export class SongRoundFacade {
	public allPopulated$: Observable<SongRound[]> = this.store
		.select(SongRoundSelectors.all)
		.pipe(
			withLatestFrom(this.playersFacade.all$),
			map(([rounds, players]: [SongRound[], Player[]]) =>
				rounds.map((round) => ({
					...round,
					players: players.filter(({ _id }: Player) =>
						round.players.includes(_id),
					),
				})),
			),
		);

	public current$: Observable<SongRound> = this.store
		.select(SongRoundSelectors.all)
		.pipe(
			withLatestFrom(this.playersFacade.current$),
			filter(
				([, currentPlayer]: [SongRound[], Player]) => !!currentPlayer,
			),
			map(([rounds, { _id: playerId }]: [SongRound[], Player]) =>
				rounds.find(({ players }: SongRound) =>
					players.includes(playerId),
				),
			),
		);

	public playersView$ = (
		songRoundId: string,
	): Observable<SongRoundPlayerView[]> =>
		this.store.select(SongRoundSelectors.playerIds(songRoundId)).pipe(
			filter((playerIds: string[]) => !!playerIds),
			withLatestFrom(
				this.playersFacade.all$,
				this.playersFacade.current$,
			),
			map(
				([roundPlayers, players, current]: [
					string[],
					Player[],
					Player,
				]) =>
					players
						.filter(({ _id }: Player) => roundPlayers.includes(_id))
						.map(({ _id, user, team }: Player, index: number) => ({
							playerId: _id,
							avatar: (user as Partial<User>).avatarUrl,
							team,
							isCurrentUser: roundPlayers[index] === current?._id,
						})),
			),
		);

	public playersViewFromPlayerRound$: Observable<SongRoundPlayerView[]> =
		this.current$.pipe(
			switchMap(({ _id }: SongRound) => this.playersView$(_id)),
		);

	public lyrics$ = (songRoundId: string): Observable<string[]> =>
		this.store
			.select(SongRoundSelectors.lyrics(songRoundId))
			.pipe(
				map((lyrics: string[]) =>
					lyrics.map((line: string) =>
						line === BREAKLINE ? '.' : line,
					),
				),
			);

	public song$ = (songRoundId: string): Observable<Song> =>
		this.store.select(SongRoundSelectors.song(songRoundId));

	public winner$ = (songRoundId: string): Observable<Partial<User>> =>
		this.store.select(SongRoundSelectors.hittedBy(songRoundId)).pipe(
			withLatestFrom(this.playersFacade.all$),
			map(([hittedBy, players]: [string, Player[]]) =>
				players.find(({ _id }: Player) => _id === hittedBy),
			),
			map((player: Player) =>
				player ? (player.user as Partial<User>) : null,
			),
		);

	constructor(
		private store: Store,
		private playersFacade: PlayersFacade,
		private songPhaseSockets: SongPhaseSockets,
		private deviceFacade: DeviceFacade,
	) {
		this.songPhaseSockets.roundUpdated$
			.pipe(
				concatLatestFrom(
					({ songRoundId }: SongPhaseRoundUpdatedEvent) =>
						this.song$(songRoundId),
				),
			)
			.subscribe(
				([{ songRoundId, hittedBy }, song]: [
					SongPhaseRoundUpdatedEvent,
					Song,
				]) => {
					this.store.dispatch(
						SongRoundActions.updateSuccess({
							round: {
								_id: songRoundId,
								hittedBy,
							},
						}),
					);

					if (hittedBy) this.deviceFacade.playAudio(song._id);
				},
			);
	}
}
