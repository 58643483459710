import { PhaseKind } from '@api/models/enums';
import { SongPhase } from '@api/models/song-phase.entity';
import { SongRound } from '@api/models/song-round.entity';
import { Song } from '@api/models/song.entity';
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { Phase } from '@bussiness/custom-models/game.custom-models';
import { createSelector } from '@ngrx/store';

import { GamesState } from '../../games.reducer';
import { selectGames } from '../../games.selectors';

export class SongRoundSelectors {
	public static all = createSelector(
		selectGames,
		(gamesState: GamesState) => {
			const songPhase = getSongPhase(gamesState);
			if (!songPhase) return [];

			return songPhase.rounds;
		},
	);

	public static lyrics = (songRoundId: string) =>
		createSelector(selectGames, (gamesState: GamesState) => {
			const songRound = getSongRoundFrom(gamesState, songRoundId);
			if (!songRound) return null;

			return (songRound.song as Song).lyrics;
		});

	public static hittedBy = (songRoundId: string) =>
		createSelector(selectGames, (gamesState: GamesState) => {
			const songRound = getSongRoundFrom(gamesState, songRoundId);
			if (!songRound) return null;

			return songRound.hittedBy;
		});

	public static song = (songRoundId: string) =>
		createSelector(selectGames, (gamesState: GamesState) => {
			const songRound = getSongRoundFrom(gamesState, songRoundId);
			if (!songRound) return null;

			return songRound.song as Song;
		});

	public static playerIds = (songRoundId: string) =>
		createSelector(selectGames, (gamesState: GamesState) => {
			const songRound = getSongRoundFrom(gamesState, songRoundId);
			if (!songRound) return null;

			return songRound.players as string[];
		});
}

const getSongPhase = (gamesState: GamesState): SongPhase => {
	if (!gamesState.current || !gamesState.current.phases) return null;

	const songPhase = gamesState.current.phases.find(
		({ kind }: Phase) => kind === PhaseKind.Song,
	) as SongPhase;

	if (!songPhase) return null;

	return songPhase;
};

const getSongRoundFrom = (
	gamesState: GamesState,
	songRoundId: string,
): SongRound => {
	const songPhase = getSongPhase(gamesState);

	if (!songPhase) return null;

	return songPhase.rounds.find(({ _id }: SongRound) => _id === songRoundId);
};
