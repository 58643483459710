import { PhaseKind } from '@api/models/enums';
import { SongPhase } from '@api/models/song-phase.entity';
import { SongRound } from '@api/models/song-round.entity';
import { Song } from '@api/models/song.entity';
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { Phase } from '@bussiness/custom-models/game.custom-models';
import { createSelector } from '@ngrx/store';

import { GamesState } from '../games.reducer';
import { selectGames } from '../games.selectors';

export class SongPhaseSelectors {
	public static allSongs = createSelector(
		selectGames,
		(gamesState: GamesState) => {
			const songPhase = getSongPhase(gamesState);
			if (!songPhase) return [];

			return songPhase.rounds.map(({ song }: SongRound) => song as Song);
		},
	);

	public static over = createSelector(
		selectGames,
		(gamesState: GamesState) => {
			const songPhase = getSongPhase(gamesState);
			if (!songPhase) return [];

			return songPhase.over;
		},
	);
}

const getSongPhase = (gamesState: GamesState): SongPhase => {
	if (!gamesState.current || !gamesState.current.phases) return null;

	const songPhase = gamesState.current.phases.find(
		({ kind }: Phase) => kind === PhaseKind.Song,
	) as SongPhase;

	if (!songPhase || !songPhase.rounds) return null;

	return songPhase;
};
