import { PhaseKind, SongClueStatus } from '@api/models/enums';
import { SongClue } from '@api/models/song-clue.entity';
import { SongPhase } from '@api/models/song-phase.entity';
import { SongRound } from '@api/models/song-round.entity';
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { Phase } from '@bussiness/custom-models/game.custom-models';
import { createSelector } from '@ngrx/store';

import { GamesState } from '../../games.reducer';
import { selectGames } from '../../games.selectors';

export type IndexedSongClue = SongClue & { index: number };

export class SongClueSelectors {
	public static current = (songRoundId: string) =>
		createSelector(selectGames, (gamesState: GamesState) => {
			const songRound = getSongRoundFrom(gamesState, songRoundId);
			if (!songRound) return null;

			const index = songRound.clues.findIndex(
				({ status }: SongClue) => status !== SongClueStatus.Answered,
			);

			if (index === -1) return null;

			return { ...songRound.clues[index], index };
		});

	public static allCluesAreAnswered = (songRoundId: string) =>
		createSelector(selectGames, (state: GamesState) => {
			const songRound = getSongRoundFrom(state, songRoundId);
			if (!songRound) return null;

			return songRound.clues.every(
				({ status }: SongClue) => status === SongClueStatus.Answered,
			);
		});

	public static lastClueAnswered = (songRoundId: string) =>
		createSelector(selectGames, (state: GamesState) => {
			const songRound = getSongRoundFrom(state, songRoundId);
			if (!songRound) return null;

			const lastClueAnswered = songRound.clues
				.filter(
					({ status }: SongClue) =>
						status === SongClueStatus.Answered,
				)
				.pop();

			if (!lastClueAnswered) return null;

			return {
				...lastClueAnswered,
				index: songRound.clues.indexOf(lastClueAnswered),
			};
		});
}

const getSongRoundFrom = (
	gamesState: GamesState,
	songRoundId: string,
): SongRound => {
	if (!gamesState.current || !gamesState.current.phases) return null;

	const songPhase = gamesState.current.phases.find(
		({ kind }: Phase) => kind === PhaseKind.Song,
	) as SongPhase;

	if (!songPhase) return null;

	return songPhase.rounds.find(({ _id }: SongRound) => _id === songRoundId);
};
